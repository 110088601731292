<!--
 * @Author: lzh
 * @Date: 2022-07-02 11:51:57
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:21:52
 * @Description: file content
-->
<template>
  <div class="dev-history-box px-dev-history">
    <div
      class="top-new"
      @click="clickItem('items')"
      :class="`edit_${Xindex}_items_${tabIndex}`"
    >
      <div
        class="yyyy"
        v-html="$util.rex.getHtml(configs.items[0][`${nowL}yyyy`] || configs.items[0].yyyy)"
      ></div>
      <div
        class="describe"
        v-html="$util.rex.getHtml(configs.items[0][`${nowL}desc`] || configs.items[0].desc)"
      ></div>
    </div>
    <div
      class="long-string"
      :class="`edit_${Xindex}_longString_${tabIndex}`"
      @click="clickItem('longString')"
    >
      <div class="xian"></div>
      <div
        v-for="(item, index) in configs.items"
        :key="index"
        v-show="index != 0"
        class="item"
        :class="`item_${
          index % 2 == 0 ? 'odd' : 'even'
        } edit_${0}_item_${index}`"
      >
        <div
          class="yyyy"
          v-html="$util.rex.getHtml(item[`${nowL}yyyy`] || item.yyyy)"
        ></div>
        <div
          class="describe"
          v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: 'self-dev-history',
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          items: [
            {
              yyyy: '2022年',
              desc: '現在',
            },
            {
              yyyy: '2021年',
              desc: '雲端餐飲管理系統RicePOS與會計系統 Xero完成技術對接業務拓展至新加坡',
            },
            {
              yyyy: '2020年',
              desc: '與著名物流系統、電子支付系統完成技術對接',
            },
            {
              yyyy: '2027年',
              desc: '全港首創固定碼點餐，建立會員系統 推出飲食著數APP“飯糰”',
            },
            {
              yyyy: '2013年',
              desc: '我們擁有了第一個合作夥伴',
            },
            {
              yyyy: '2012年',
              desc: '香港成立 Gingersoft',
            },
          ],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
    tabIndex: {
      default () {
        return '';
      },
    },
  },
  methods: {
    clickItem (e) {
      let isEdit = this.$store.getters.getIsEdit;
      if (isEdit) {
        this.$emit('clickItem', e, this.tabIndex);
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dev-history-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  position: relative;
  .top-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
  }
  .long-string {
    position: relative;
    width: 100%;
    padding-bottom: 130px;
    margin: 20px 0;
    .xian {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      width: 1px;
      height: 100%;
      background-color: #eee;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--themeColor);
        box-shadow: 0 0 0 6px var(--themeColor1);
      }
      &::after {
        z-index: 2;
        content: "";
        position: absolute;
        bottom: 0;
        right: -5px;
        width: 11px;
        height: 100px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 1)
        );
      }
    }
    display: flex;
    flex-direction: column;
    .item {
      width: calc(50% - 32px);
      margin-top: 100px;
    }
    .item_even {
      align-self: flex-start;
      text-align: right;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -36px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--themeColor);
        box-shadow: 0 0 0 6px var(--themeColor1);
      }
      .yyyy {
        position: relative;
        display: inline;
        padding: 4px 6px;
        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 15px);
          left: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: linear-gradient(
            90deg,
            var(--themeColor),
            var(--themeColor1)
          );
          opacity: 0.2;
        }
      }
    }
    .item_odd {
      align-self: flex-end;
      text-align: left;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -36px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--themeColor);
        box-shadow: 0 0 0 6px var(--themeColor1);
      }
      .yyyy {
        position: relative;
        display: inline;
        padding: 4px 6px;
        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 15px);
          right: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: linear-gradient(
            90deg,
            var(--themeColor1),
            var(--themeColor)
          );
          opacity: 0.2;
        }
      }
    }
  }
  .yyyy {
    font-size: 12px;
    font-weight: 400;
    color: var(--themeColor);
  }
  .describe {
    font-size: 16px;
    // font-weight: bold;
    color: #000000;
    margin-top: 6px;
  }
}
@media screen and (max-width: 1000px) {
  .px-dev-history {
    padding: 0 28px;
    .top-new {
      margin-top: 25px;
    }
    .yyyy {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--themeColor);
    }
    .describe {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .long-string {
      .item {
        margin-top: 70px;
      }
    }
  }
}
</style>
